import { apiTo } from './api'
import { list_functionality } from '@/services/list-functionality/list-functionality'
import { mapGetters, mapMutations } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
//Components

import ViewInvoice from '@/modules/Invoice/components/ViewInvoice/ViewInvoice.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/Actions.vue'
import TableHeader from '@/common/TableHeader.vue'
export default {
    name: 'Invoice',
    mixins: [list_functionality],

    components: {
        VueTable,
        DeleteDialog,
        ViewInvoice,
        Actions,
        TableHeader
    },

    props: {
        id: [Number, String]
    },

    data: () => ({
        headers: [
            { text: 'Invoice ID', width: 100, sortable: false },
            { text: 'Name' },
            { text: 'Billed To', width: 150, sortable: false },
            { text: 'Billed From', width: 150, sortable: false },
            { text: 'Due Date', width: 150 },
            { text: 'Amount', width: 120 },
            { text: 'Status', width: 120 },
            { text: 'Actions', width: 150, align: 'center', sortable: false }
        ],
        view_invoice_dialog: false,
        view_item: null,
        next_page_url: null
    }),

    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.getInvoices()
    },
    computed: {
        ...mapGetters(['user_roles', 'user']),
        type() {
            return this.$route.params.type || 'project'
        },
        paths() {
            return [{
                    text: 'Dashboard',
                    disabled: false,
                    route: { name: 'default-content' }
                },
                {
                    text: this.type,
                    disabled: false,
                    route: { path: `/dashboard/${this.type}/preview/${this.id}` }
                },
                { text: 'Invoice', disabled: true, route: null }
            ]
        },
        can_add() {
            return !this.user_roles.includes('client')
        }
    },
    methods: {
        createNewProjectInvoice() {
            this.$router
                .push({ name: 'invoice-maker', query: { project_id: this.id } })
                .catch(() => {})
        },
        can_edit(invoice) {
            return this.user.id !== invoice.billable_to.id &&
                !this.user_roles.includes('client') ?
                true :
                false
        },
        can_delete(invoice) {
            return this.user.id === invoice.billable_from.id ||
                this.user_roles.includes('client') ?
                false :
                true
        },
        async delete_invoice() {
            this.loading = true
            this.delete_dialog = false
            await apiTo.delete_invoice(this.delete_item_id)
            this.loading = false
            const index = this.items.findIndex(
                (item) => item.id === this.delete_item_id
            )
            if (~index) this.items.splice(index, 1)
        },
        open_view_dialog(data) {
            this.view_item = data
                // this.view_invoice_dialog = true;
            this.popupCenter({
                url: data.view_url,
                title: data.title,
                w: 900,
                h: 550
            })
        },

        open_edit_dialog(data) {
            this.$router.push({ name: 'invoice-maker', params: { id: data.id } })
            this.$store.commit('invoice/set_is_editting', true)
        },

        getInvoices() {
            this.loading = true
            apiTo
                .getInvoices(this.id)
                .then(({ data }) => {
                    this.items = data.data
                    this.next_page_url = data.next_page_url
                })
                .finally(() => {
                    this.loading = false
                    this.$event.$emit('btnloading_off', false)
                })
        },
        getMoreInvoices() {
            this.loading = true
            apiTo
                .getMoreInvoices(this.next_page_url)
                .then(({ data }) => {
                    this.items.push(...data.data)
                    this.next_page_url = data.next_page_url
                })
                .finally(() => {
                    this.loading = false
                    this.$event.$emit('btnloading_off', false)
                })
        },
        invoice_updated(invoice) {
            const index = this.items.findIndex((item) => item.id === invoice.id)
            if (~index) {
                this.items.splice(index, 1, invoice)
            }
        }
    }
}